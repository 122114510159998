<template>
  <div class="container">
    <header class="jumbotron">
      <h1>Witamy w panelu użytkownika</h1>
      <h3>{{ content }}</h3>
    </header>
  </div>
  <table class="table table-bordered">
    <thead>
      <tr>
        <h4><th scope="col">id</th></h4>
        <th scope="col">Imię</th>
        <th scope="col">Nazwisko</th>
        <th scope="col">Data urodzenia</th>
        <th scope="col">Miejsce urodzenia</th>
        <th scope="col">Stanowisko</th>
        <th scope="col">Email</th>
        <th scope="col">Data ważności</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="cont in content" :key="cont.id">
        <td>{{cont.id}}</td>
        <td>{{cont.names}}</td>
        <td>{{cont.lastName}}</td>
        <td>{{cont.birthDate}}</td>
        <td>{{cont.birthPlace}}</td>
        <td>{{cont.position}}</td>
        <td>{{cont.email}}</td>
        <td>{{cont.validDate}}</td>
        
      </tr>
    </tbody>
  </table>
</template>
<!-- lastName": "Biniek", "birthDate": "2000-05-31T22:54:03.287", "birthPlace": "Warszawa", "position" -->
<script>
import UserService from "../services/user.service";

export default {
  name: "User",
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    UserService.getUserBoard().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>
